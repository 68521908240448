import React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PillButton } from "../UI/PillButton"
import {
  bgClass
} from "./ImageAndTextPanel.module.scss"
import SectionHeading from "../UI/SectionHeading"
export const fragment = graphql`
  fragment imageAndTextPanelFragment on WpPage_Flexlayouts_FlexibleLayouts_ImageAndTextPanel {
    sectionHeading
    headingType
    text
    image {
      ...GatsbyImageQuery
    }
    button {
      url
      title
    }
  }
`

const ImageAndTextPanel = ({ sectionHeading,headingType, text, image, button }) => {
  const img = getImage(image?.localFile)

  return (
    <section className={`bg-pale-grey`}>
      <Container>
        <Row className={`${bgClass} align-items-center pb-5`}>
          <Col
            sm={12}
            md={6}
            lg={4}
            xl={3}
            className="mt-5 mt-lg-7 d-flex justify-content-center align-self-lg-baseline">
            <GatsbyImage image={img} alt={img?.altText} />
          </Col>
          <Col sm={12} md={6} lg={8} xl={9} className="mx-auto">
            <div className={`d-flex flex-column`}>         
              <SectionHeading 
                text={sectionHeading} 
                type={headingType} 
                className="text-white py-4 pt-lg-5 text-center text-lg-start"
               />    
              <div
                dangerouslySetInnerHTML={{ __html: text }}
                className="px-3 px-sm-0 pb-5 quicksand-500 text-white text-center text-lg-start"
              />
              {button && (
                <PillButton
                  link={button.url}
                  title={button.title}
                  classes="px-6"
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ImageAndTextPanel